import React, { useEffect } from "react";
import "./UserManagement.css";
import { iconsIndex } from "../icons/icons_index";
import DataTbl from "../components/DataTbl";
import { useState } from "react";
import ExpandSearch from "../components/ExpandSearch";
import ActionModal from "../components/ActionModal";
import moment from "moment";
import toastNotify from "../components/Toast.js";
import { ToastContainer, toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import { fetchData } from "../Utils";
import { useUserAuth } from "../hooks/useUserAuth.js";
import "../GlobalVariables";
import {
  Button,
  Combobox,
  Input,
  Select,
  TextArea,
  CheckboxGroup,
  Checkbox,
} from "@appkit4/react-components";
export default function UserManagementPage() {
  // User Data table styling
  const customStyles = {
    headCells: {
      style: {
        color: "#ffffff",
        backgroundColor: "#203570",
        border: "0px solid #fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
      },
    },
    headRow: {
      style: {
        borderRadius: "3px",
        fontSize: "14px",
      },
    },
    rows: {
      style: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "4px",

        // color: theme.text.primary,
        // backgroundColor: '#656565',
        minHeight: "48px",
        "&:not(:last-of-type)": {
          borderBottomStyle: "none",
          // borderBottomWidth: '1px',
          // borderBottomColor: theme.divider.default,
        },
      },
      denseStyle: {
        minHeight: "32px",
      },
    },
    expanderButton: {
      style: {
        color: "#FFFFFF",
        fill: "#FFFFFF",
        backgroundColor: "#000000",
        borderRadius: "2px",
        transition: "0.25s",
        height: "100%",
        width: "100%",
        "&:hover:enabled": {
          cursor: "pointer",
        },
        "&:disabled": {
          color: "#A0A0A0",
        },
        "&:hover:not(:disabled)": {
          cursor: "pointer",
          backgroundColor: "#A0A0A0",
        },
        "&:focus": {
          outline: "none",
          backgroundColor: "#FFFFFF",
        },
        svg: {
          margin: "auto",
        },
      },
    },
  };

  const handleButtonClick = (e, row_data) => {
    e.preventDefault();
    getUserDetails(row_data);
  };
  const columns = [
    {
      name: "User Name",
      selector: (row) => row.user_name,
      sortable: true,
      button: true,
      width: "350px",
      headerStyle: (selector, id) => {
        return { textAlign: "left" };
      },
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        marginLeft: "12px",
      },
      cell: (row) => (
        <button
          className="username-button"
          onClick={(e) => handleButtonClick(e, row)}
        >
          {row.user_name}
        </button>
      ),
    },
    {
      name: "User Role",
      selector: (row) => row.user_type,
      sortable: true,
    },
    {
      name: "Modified by",
      selector: (row) => row.modified_by,
      sortable: true,
    },
    {
      name: "Modified on",
      selector: (row) => row.modified_on,
      format: (row) => moment(Number(row.modified_on)).format("DD-MMM-YYYY"),
      sortable: true,
    },
    {
      name: "Active Deals",
      selector: (row) => row.active_deals,
      sortable: true,
    },
  ];
  // Get latest user data from DB
  const [users_data, setUsersData] = useState([]);
  const [usersFetched, setUsersFetched] = useState(false);
  const [displayState, setDisplayState] = useState("user_management");
  const [showLoader, setShowLoader] = useState(false);
  const [filteredUserData, setFilteredUserData] = useState(users_data);
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(true);

  const userFetchCallback = (data) => {
    if (data != null) {
      setUsersData(data.user);
      setFilteredUserData(data.user);
      setUsersFetched(true);
      setShowLoader(false);
    } else {
      setUsersData([]);
      setFilteredUserData([]);
      setUsersFetched(true);
      setShowLoader(false);
      toastNotify("Users update failed!", "error");
    }
  };
  useEffect(() => {
    setShowLoader(true);
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer 1234",
    };
    var response = fetchData(
      "get_all_users",
      {},
      "POST",
      headers,
      userFetchCallback
    );
  }, [displayState]);

  // Set Search state
  const { user, setUser } = useUserAuth();
  const filterUserData = (search_query) => {
    if (search_query.length === 0 || search_query.length === null) {
      setFilteredUserData(users_data);
    } else {
      const search_string = search_query.toLowerCase();
      const search_results = users_data.filter(function (user) {
        return user.user_name.toLowerCase().includes(search_string);
      });
      setFilteredUserData(search_results);
    }
  };

  // Form params for new user
  const [userNameValue, setUserNameValue] = useState("");
  const [userMobileValue, setUserMobileValue] = useState("");
  const [userEmailValue, setUserEmailValue] = useState("");
  const [userDesignationValue, setUserDesignationValue] = useState("");
  const [userType, setUserType] = useState(null);

  // Form params for existing user
  const [userNameValueExisting, setUserNameValueExisting] = useState("");
  const [userMobileValueExisting, setUserMobileValueExisting] = useState("");
  const [userEmailValueExisting, setUserEmailValueExisting] = useState("");
  const [userDesignationValueExisting, setUserDesignationValueExisting] =
    useState("");
  const [userTypeExisting, setUserTypeExisting] = useState(null);

  // Fetch details from backend - set initial state values from there
  const getUserDetails = (row_data) => {
    // Fetch User details
    setDisplayState("edit_user");
    setUserNameValueExisting(row_data.user_name);
    setUserMobileValueExisting(row_data.mobile_no);
    setUserEmailValueExisting(row_data.user_id);
    setUserDesignationValueExisting(row_data.designation);
    setUserTypeExisting(row_data.user_type);
  };

  const userTypeOptions = [
    { value: "User", label: "User" },
    { value: "Admin", label: "Admin" },
  ];
  const userTypeChange = (selectedOption) => {
    setUserType(selectedOption);
  };
  const userTypeChangeExisting = (selectedOption) => {
    setUserTypeExisting(selectedOption);
  };
  const selectCSSStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#ffffff",
      fontSize: "14px",
      width: "200px",
      padding: "0px",
      minHeight: "20px",
      height: "25px",
      outline: "none",
      border: "none",
      boxShadow: "none",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f0f0f0" : "#ffffff",
        color: "#000000",
        width: "200px",
      };
    },
  };

  // State for deleting users
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const cancelDeleteUser = () => {
    setShowDeleteModal(false);
  };

  const confirmUserDeleted = (response) => {
    setShowLoader(false);
    setShowDeleteModal(false);
    if (response["status"] === 200 && response["errors"] === false) {
      toastNotify(response["msg"], "success");
    } else if (response["status"] === 200 && response["errors"] === true) {
      toastNotify(response["msg"], "error");
    } else if (response["status"] === 500) {
      toastNotify("Server Connection Error!", "error");
    }
    setDisplayState("user_management");
  };
  const confirmDeleteUser = (e) => {
    // Call the delete user API by passing variable - userEmailValueExisting
    // e.preventDefault()
    setShowDeleteModal(true);
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer 1234",
    };
    var paylod = {
      user_id_to_delete: userEmailValueExisting,
      user_id: user.user_id,
    };
    setShowLoader(true);
    var response = fetchData(
      "delete_user",
      paylod,
      "POST",
      headers,
      confirmUserDeleted
    );
  };

  const delete_modal_action_buttons = [
    {
      text: "No, Cancel",
      style: {
        cursor: "pointer",
        width: "125px",
        height: "35px",
        backgroundColor: "#ffffff",
        border: "2px solid #DE3163",
        color: "#DE3163",
        padding: "5px",
        margin: "10px",
        fontSize: "14px",
        fontWeight: "bold",
        outline: "none",
      },
      callback: cancelDeleteUser,
      type: "secondary",
    },
    {
      text: "Yes, Delete",
      style: {
        cursor: "pointer",
        width: "125px",
        height: "35px",
        backgroundColor: "#DE3163",
        border: "2px solid #DE3163",
        color: "#ffffff",
        padding: "5px",
        margin: "10px",
        fontSize: "14px",
        fontWeight: "bold",
        outline: "none",
      },
      callback: confirmDeleteUser,
      type: "primary",
    },
  ];

  // Call create user API
  const confirmUserAdded = (response) => {
    setShowLoader(false);
    if (response["status"] === 200 && response["errors"] === false) {
      toastNotify(response["msg"], "success");
    } else if (response["status"] === 200 && response["errors"] === true) {
      toastNotify(response["msg"], "error");
    } else if (response["status"] === 500) {
      toastNotify("Server Connection Error!", "error");
    }
    setUserNameValue("");
    setUserMobileValue("");
    setUserEmailValue("");
    setUserDesignationValue("");
    setUserType(null);
  };
  const confirmCreateUser = async (e) => {
    // Call the delete user API by passing variable - userEmailValueExisting
    setShowLoader(true);
    e.preventDefault();
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer 1234",
    };
    var paylod = {
      user_id: userEmailValue,
      user_name: userNameValue,
      user_type: userType,
      mobile_no: userMobileValue,
      modified_by: user.user_id,
      modified_on: Date.now(),
      designation: userDesignationValue,
    };

    var response = await fetchData(
      "add_user",
      paylod,
      "POST",
      headers,
      confirmUserAdded
    );
    setFilteredUserData(users_data);
    setDisplayState("user_management");
  };

  // Call create user API
  const confirmUserUpdated = (response) => {
    setShowLoader(false);
    if (response["status"] === 200 && response["errors"] === false) {
      toastNotify(response["msg"], "success");
    } else if (response["status"] === 200 && response["errors"] === true) {
      toastNotify(response["msg"], "error");
    } else if (response["status"] === 500) {
      toastNotify("Server Connection Error!", "error");
    }
  };
  const confirmUpdateUser = async (e) => {
    // Call the delete user API by passing variable - userEmailValueExisting
    setShowLoader(true);
    e.preventDefault();
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer 1234",
    };
    var paylod = {
      user_id: userEmailValueExisting,
      user_name: userNameValueExisting,
      user_type: userTypeExisting,
      mobile_no: userMobileValueExisting,
      modified_by: user.user_id,
      modified_on: Date.now(),
      designation: userDesignationValueExisting,
    };
    var response = await fetchData(
      "edit_user_details",
      paylod,
      "POST",
      headers,
      confirmUserUpdated
    );
  };

  useEffect(() => {
    checkIsDisabled();
  }, [
    userNameValue,
    userType,
    userMobileValue,
    userEmailValue,
    userDesignationValue,
  ]);

  // hp - checking the button is disabled or not
  const checkIsDisabled = (e) => {
    if (!userNameValue.length) {
      setIsSaveBtnDisabled(true);
    } else if (userType == null) {
      setIsSaveBtnDisabled(true);
    } else if (!userMobileValue.length) {
      setIsSaveBtnDisabled(true);
    } else if (!userEmailValue.length) {
      setIsSaveBtnDisabled(true);
    } else if (!userDesignationValue.length) {
      setIsSaveBtnDisabled(true);
    } else {
      setIsSaveBtnDisabled(false);
    }
  };

  if (displayState === "user_management") {
    return usersFetched ? (
      <div className="page_content">
        <div className="menubar_simple">
          <div className="menubar_title">User Management</div>
          <div className="menubar_actions">
            <ExpandSearch
              filtering_function={filterUserData}
              search_placeholder="Search user"
              direction="left"
            ></ExpandSearch>
            <Button
              kind="primary"
              compact
              icon="icon-circle-plus-outline"
              onClick={() => {
                setDisplayState("create_user");
              }}
            >
              Create User
            </Button>
            {/* <div
                  className="menubar_actions_create"
                  onClick={() => {
                    setDisplayState("create_user");
                  }}
                >
                  <img
                    style={{
                      backgroundColor: "#289D8E",
                      padding: "2px",
                      margin: "2px",
                    }}
                    src={iconsIndex["create_circle_icon"]}
                    alt=""
                    width="20px"
                    height="20px"
                  ></img>
                  <span
                    style={{
                      width: "100px",
                      backgroundColor: "#289D8E",
                      padding: "5px",
                      margin: "5px",
                      fontWeight: "400",
                      color: "white",
                    }}
                  >
                    Create User
                  </span>
                </div> */}
          </div>
        </div>
        <DataTbl
          highlightOnHover={true}
          classname="user_table"
          columns={columns}
          data={filteredUserData}
          customStyles={customStyles}
          pagination_per_page={15}
          pagination_options={[10, 15, 20, 25, 50, 100]}
        />
        {showLoader && (
          <div className="loader-dial">
            <div className="stop-overlay"></div>
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={true}
            />
          </div>
        )}
        <ToastContainer autoClose={1000} />
      </div>
    ) : (
      <div className="loader-dial">
        <div className="stop-overlay"></div>
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
      </div>
    );
  } else if (displayState === "create_user") {
    return (
      <div className="page_content">
        <div className="menubar_simple">
          <div
            className="menubar_action_left"
            onClick={() => {
              setFilteredUserData(users_data);
              setDisplayState("user_management");
            }}
          >
            <img
              src={iconsIndex["arrow_back_icon"]}
              alt=""
              width="25px"
              height="25px"
            ></img>
          </div>
          <div className="menubar_title title_right">Create User</div>
          <div className="menubar_actions">
            <Button
              kind="secondary"
              compact
              onClick={() => {
                setUserNameValue("");
                setUserType("");
                setUserMobileValue("");
                setUserEmailValue("");
                setUserDesignationValue("");
              }}
              style={{ margin: "10px" }}
            >
              Cancel
            </Button>
            <Button
              kind="primary"
              compact
              onClick={(e) => {
                confirmCreateUser(e);
              }}
              style={{ margin: "10px" }}
            >
              Submit
            </Button>
          </div>
        </div>
        <div className="user_actions_outer">
          <div className="user_actions_inner">
            <div
              style={{
                width: "200px",
                marginLeft: "1.5%",
                marginTop: "20px",
                color: "#203570",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Basic Details
            </div>
            <div className="userdata_form">
              <div>
                <label style={{ color: "#203570", fontSize: "14px" }}>
                  User Name
                </label>
                <br></br>
                <Input
                  type={"text"}
                  value={userNameValue}
                  onChange={(e) => {
                    setUserNameValue(e);
                  }}
                  hideTitleOnInput
                />
              </div>
              <div className="user_details">
                <label style={{ color: "#203570", fontSize: "14px" }}>
                  User Type
                </label>
                <br></br>
                <Select
                  data={userTypeOptions}
                  placeholder="Select"
                  onSelect={(vals) => userTypeChange(vals)}
                  value={userType}
                  hideTitleOnInput
                />
              </div>
              <div>
                <label style={{ color: "#203570", fontSize: "14px" }}>
                  Mobile Number
                </label>
                <br></br>
                <Input
                  type={"text"}
                  value={userMobileValue}
                  onChange={(e) => {
                    setUserMobileValue(e);
                  }}
                  hideTitleOnInput
                />
              </div>
              <div>
                <label style={{ color: "#203570", fontSize: "14px" }}>
                  Email Id
                </label>
                <br></br>
                <Input
                  type={"text"}
                  value={userEmailValue}
                  onChange={(e) => {
                    setUserEmailValue(e);
                  }}
                  hideTitleOnInput
                />
              </div>
              <div>
                <label style={{ color: "#203570", fontSize: "14px" }}>
                  Designation
                </label>
                <br></br>
                <Input
                  type={"text"}
                  value={userDesignationValue}
                  onChange={(e) => {
                    setUserDesignationValue(e);
                  }}
                  hideTitleOnInput
                />
              </div>
            </div>
          </div>
        </div>
        {showLoader && (
          <div className="loader-dial">
            <div className="stop-overlay"></div>
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={true}
            />
          </div>
        )}
        {/*<ToastContainer autoClose={1000} />*/}
      </div>
    );
  } else if (displayState === "edit_user") {
    return (
      <div className="page_content">
        <div className="menubar_simple">
          <div
            className="menubar_action_left"
            onClick={() => {
              setFilteredUserData(users_data);
              setDisplayState("user_management");
            }}
          >
            <img
              src={iconsIndex["arrow_back_icon"]}
              alt=""
              width="25px"
              height="25px"
            ></img>
          </div>
          <div className="menubar_title title_right">Edit User Information</div>
          <div className="menubar_actions">
            <Button
              kind="primary"
              compact
              onClick={(e) => {
                confirmUpdateUser(e);
              }}
              style={{ margin: "10px" }}
            >
              Update
            </Button>
            <Button
              kind="secondary"
              compact
              onClick={(e) => {
                setShowDeleteModal(true);
              }}
              style={{ margin: "10px" }}
            >
              Delete
            </Button>
          </div>
        </div>
        <div className="user_actions_outer">
          <div className="user_actions_inner">
            <div
              style={{
                width: "200px",
                marginLeft: "1.5%",
                marginTop: "20px",
                color: "#203570",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              Basic Details
            </div>
            <div className="userdata_form">
              <div>
                <label style={{ color: "#203570" }}>User Name</label>
                <br></br>
                <Input
                  type={"text"}
                  value={userNameValueExisting}
                  onChange={(e) => {
                    setUserNameValueExisting(e);
                  }}
                  hideTitleOnInput
                />
              </div>
              <div className="user_details">
                <label style={{ color: "#203570" }}>User Type</label>
                <br></br>
                <Select
                  data={userTypeOptions}
                  placeholder="Select"
                  onSelect={(vals) => userTypeChangeExisting(vals)}
                  value={userTypeExisting}
                  hideTitleOnInput
                />
              </div>
              <div>
                <label style={{ color: "#203570" }}>Mobile Number</label>
                <br></br>
                <Input
                  type={"text"}
                  value={userMobileValueExisting}
                  onChange={(e) => {
                    setUserMobileValueExisting(e);
                  }}
                  hideTitleOnInput
                />
              </div>
              <div>
                <label style={{ color: "#203570" }}>Email Id</label>
                <br></br>
                <Input
                  type={"text"}
                  value={userEmailValueExisting}
                  onChange={(e) => {
                    setUserEmailValueExisting(e);
                  }}
                  hideTitleOnInput
                />
              </div>
              <div>
                <label style={{ color: "#203570" }}>Designation</label>
                <br></br>
                <Input
                  type={"text"}
                  value={userDesignationValueExisting}
                  onChange={(e) => {
                    setUserDesignationValueExisting(e);
                  }}
                  hideTitleOnInput
                />
              </div>
            </div>
          </div>
        </div>
        {showDeleteModal && (
          <ActionModal
            actions_obj={delete_modal_action_buttons}
            heading="Delete User?"
            msg="All data related to this user will be lost permanently.&#10;Are you sure you want to delete this user?"
          ></ActionModal>
        )}
        {showLoader && (
          <div className="loader-dial">
            <div className="stop-overlay"></div>
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={true}
            />
          </div>
        )}
        <ToastContainer autoClose={1000} />
      </div>
    );
  }
}
